body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.no-select {
		user-select: none;
		cursor: col-resize; /* Or a relevant cursor for resizing */
	}

	.header {
		display: flex;
		flex-direction: row;
		padding: 11px 20px;
		align-items: center;
		background: rgb(33,150,243);
		background: linear-gradient(90deg, rgba(33,150,243,1) 50%, rgba(141,60,255,1) 100%);
		color: lightgrey;

		.logo {
			margin-right: 40px;
			font-size: 34px;
			font-weight: bold;
			font-family: 'Roboto', sans-serif;
			color: white;
			letter-spacing: -1px;
		}

		.nav {
			display: flex;
			margin: auto;

			&-item {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 55px;
				width: 200px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				margin-bottom: -17px;
				font-weight: bold;
				font-size: 16px;
				cursor: pointer;

				&.is-active {
					background-color: rgba(255, 255, 255, 0.2);
					color: white;
				}
			}
		}

		.user {
			display: flex;
			justify-content: space-between;

			* {
				color: white;
			}
		}
	}

	.content-wrapper {
		display: flex;
		height: calc(100vh - 78px);

		.main-content {
			flex: 1;
			padding: 8px 10px 20px 20px;
			background-color: #f0f0f0;

			.filters-wrapper {
				margin: 10px 0 30px;

				.filters-row {
					margin-bottom: 15px;
					display: flex;
					align-items: center;

					&-dates-wrapper {
						.date-picker {
							margin: 0 20px 0 0;
							min-width: 150px;
							width: 200px;

							+ .date-picker {
								margin-right: 5px;
							}
						}
					}

					.dropdown {
						width: 170px;
						margin-left: 40px;

						+ .dropdown {
							margin-left: 20px;
						}

						.MuiFormLabel-root {
							margin-left: -13px;
						}
					}

					.interaction-filter {
						width: 100%;
						max-width: 170px;
						margin-left: 30px;

						.label {
							color: #666;
							margin-bottom: 15px;
							display: block;
						}
					}

					input {
						font-weight: bold!important;
					}

					.MuiInputBase-root * {
						font-weight: bold!important;
					}
				}
			}

			.no-data-view {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: 100px 0;

				.text {
					margin: 50px 0;
				}
			}

			.table-buttons {
				display: flex;
				justify-content: space-between;
				width: 102%; // hack to override
				margin-left: -20px; // table header

				.reset-filter {
					display: flex;
					align-items: center;
					margin-left: 10px;
					font-size: 14px;
					color: #666;

					.button {
						display: flex;
						align-items: center;
						margin-left: 20px;
						text-decoration: underline;
						cursor: pointer;

						&:hover {
							color: #0175ff;
						}

						.icon {
							width: 20px;
							margin-right: 5px;
						}
					}
				}

				.excel-download {
					margin-left: auto;

					.text {
						font-size: 14px;
						color: #666;
					}

					&-button {
						margin-left: 10px;
					}
				}
			}

			// React table header labels
			.rdt_TableHead {
				font-weight: bold;
				font-size: 16px;
			}

			.rdt_TableRow {
				min-height: 60px;
				font-size: 16px;
			}
		}

		.sidebar {
			width: 500px;
			min-width: 350px;
			max-width: 50vw; /* Max width set to 50% of viewport width */
			// background-color: #e4e4e4;
			position: relative;
			display: flex;
			flex-direction: column;
			font-size: 14px;

			.meta-data {
				border: 1px solid #ddd;
				// overflow: hidden;
				transition: max-height 0.3s ease;
				max-height: 80px;
				position: relative;
				padding: 13px 10px 10px 20px;
				flex-shrink: 0;

				&-header {
					display: flex;
					flex-direction: column;

					.headline {
						display: flex;
						align-items: center;

						.session-id {
							cursor: pointer;
							display: flex;
							align-items: center;

							.copy-icon {
								font-size: small;
								margin-left: 4px;
							}
						}

						.navigation {
							margin-left: auto;

							button + button {
								transform: rotate(180deg);
							}
						}
					}
				}

				&-content {
					max-height: 0;
					overflow: hidden;
					transition: max-height 0.3s ease, opacity 0.3s ease;
					margin-top: 26px;

					.row {
						display: flex;
						width: 100%;
						margin-bottom: 15px;

						.key {
							width: 100px;
							flex-shrink: 0;
							white-space: nowrap;
							font-weight: bold;
						}
					}
				}

				.toggle-button {
					display: flex;
					align-items: center;
					cursor: pointer;
					position: absolute;
					bottom: -17px;
					right: 17px;
					transition: transform .5s ease;
					color: #CDD0D4;
					background-color: white;
					border-radius: 50%;
				}
			}

			.chat-area {
				overflow-y: auto;
				background-color: #ffffff;
				padding: 20px;

				.chat-row {
					display: flex;
					flex-direction: row;
					margin-bottom: 10px;

					.chat-message {
						max-width: 80%;
						font-size: 16px;
						line-height: 21px;
						font-weight: 400;
						color: white;
						background-color: #8D3CFF;
						padding: 8px 12px;
						border-radius: 8px 8px 8px 0;
					}

					.chat-meta {
						margin-top: 5px;

						.message-type {
							color: #282c34;
							font-weight: bold;
						}

						.time-stamp {
							color: #666666;
						}
					}

					&.is-user {
						justify-content: flex-end;

						.chat-message {
							margin-left: auto;
							background-color: #2196F3;
							border-radius: 8px 8px 0 8px;
						}

						.chat-meta {
							display: flex;
							justify-content: flex-end;
						}
					}


				}
			}

			// Element for handling sidebar width
			.resizer {
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
				cursor: ew-resize;
				background-color: #ddd;
				opacity: 0.7;
				transition: background-color 0.2s ease;
				z-index: 1;

				&:after {
					content: '';
					position: absolute;
					left: -9px;
					top: 107px;
					display: inline-block;
					width: 22px;
					height: 22px;
					--svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23e9e9e9' d='M12 4v11H5.83l2.58-2.59L7 11l-5 5l5 5l1.41-1.41L5.83 17H12v11h2V4zm13 7l-1.41 1.41L26.17 15H20V4h-2v24h2V17h6.17l-2.58 2.59L25 21l5-5z'/%3E%3C/svg%3E");
					background-color: #6f6f6f;
					-webkit-mask-image: var(--svg);
					mask-image: var(--svg);
					-webkit-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					-webkit-mask-size: 100% 100%;
					mask-size: 100% 100%;
				}
			}

			.resizer:hover {
				// background-color: #c5c5c5;
				width: 10px;
			}
		}
	}

	.recent-indicator {
		margin-left: 10px;
		align-items: center;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #00b500;
		display: inline-block;
		animation: pulse 3s infinite;

		&.is-in-table {
			margin-left: 20px;
		}
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 1;
		}
		50% {
			transform: scale(1.3);
			opacity: 0.5;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	// Temporary style - reuse style for page layout
	// separate style for specific pages
	.user-management {
		margin: 40px;

		.go-back-button {
			position: absolute;
			right: 40px;
			color: #717171;
		}

		.table-wrapper {
			display: flex;

			.user-management-table {
				padding: 20px 20px 10px;
				width: 50%;
				border: 1px solid #ddd;
				border-radius: 5px;

				.add-button {
					margin-top: 10px;
					margin-left: auto;
					margin-bottom: 10px;
					display: flex;
				}
			}

			.edit-user-area {
				display: flex;
				flex-direction: column;
				padding: 20px;
				border: 1px solid #ddd;
				border-radius: 5px;
				margin-left: 20px;
				width: 50%;
				position: relative;

				.input {
					width: 220px;
					margin-bottom: 40px;
				}

				.action-buttons {
					display: flex;
					justify-content: space-between;
					margin-top: auto;
				}
			}
		}
	}

	.excel-popper {
		z-index: 1;

		.list {
			width: 100%;
			border: 1px solid #ddd;
			border-radius: 3px;
			padding: 0;
			background-color: white;

			.list-item {
				height: 30px;
				cursor: pointer;
				font-size: 14px;

				&:hover {
					background-color: #e4e4e4;
				}
			}
		}
	}

	.analytics-page {
		margin: 20px;

		.filters {
			display: flex;
			align-items: flex-end;
			margin: 40px 0;

			.dropdown {
				width: 180px;
				margin-left: 40px;

				+ .dropdown {
					margin-left: 20px;
				}

				.MuiFormLabel-root {
					margin-left: -13px;
				}
			}

			.time-range {
				margin-left: 40px;

				.date-picker {
					margin: 0 20px 0 0;
					min-width: 150px;
					width: 200px;

					+ .date-picker {
						margin-right: 5px;
					}
				}
			}
		}

		//.charts {
		//
		//}
		//
		//.table-wrapper {
		//	display: flex;
		//
		//	.analytics-table {
		//		width: 70%;
		//	}
		//
		//	.analytics-details {
		//		border: 1px solid #ddd;
		//		padding: 10px 30px;
		//		border-radius: 5px;
		//		width: 30%;
		//	}
		//}

		.analytics-container {
			display: flex;
			justify-content: space-between;
			margin: 20px;
		}

		.charts {
			width: 75%; /* 80% width for charts */
			margin-right: 20px;
		}

		.analytics-details {
			width: 25%; /* 20% width for the details section */
			position: -webkit-sticky; /* For Safari */
			position: sticky;
			top: 20px; /* Adjust the value as needed for spacing */
			padding: 20px;
			background-color: #fff; /* Optional: adds background color */
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow */
			height: 100%;
			overflow-y: auto; /* Optional: enables scrolling within the details block */
		}

		.chart-wrapper div {
			margin-bottom: 40px;
		}

	}
}
